import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../styles/contactSection.css'

import logo from '../images/logo-tp.png'
import EBookModal from "./eBookModal";

const ContactSection = () => {

    return(
        <section className='contact tsection bg-dark'>
            <div className='container-xl px-0 py-3'>
                <div className='row text-light gap-4 justify-content-center gap-2'>
                    <div className='col-lg-3 col-6'>
                        <img src={logo} className='img-fluid' alt="logo" />
                    </div>
                    <div className='col-lg-4 col-11 text-center'>
                        <h3 className='text-info'>Contact</h3>
                        <hr />
                        <p>
                            <i className="bi bi-geo-alt-fill p-2 text-info" />
                            16 5th Street, McMechen, WV 26040
                        </p>
                        <p>
                            <i className="bi bi-telephone-fill p-2 text-info" />
                            Call or Text (304)-281-4451
                        </p>
                        <p>
                            <i className="bi bi-envelope-fill p-2 text-info" />
                            13spadesdealeracademy@gmail.com
                        </p>
                    </div>
                    <div className='col-lg-4 col-11 text-center'>
                        <h3 className='text-center text-info'>Follow Us</h3>
                        <hr />
                        <div className='row justify-content-center'>
                            <a className='col-2' href='https://www.facebook.com/13-Spades-Dealer-Academy-108772405146429' target="_blank" rel="noopener noreferrer">
                                <h1>
                                    <i className="bi bi-facebook text-info" />
                                </h1>
                            </a>
                            <a href='https://www.instagram.com/' className='col-2'>
                                <h1>
                                    <i className="bi bi-instagram text-info" />
                                </h1>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row text-light justify-content-center'>
                    <div className='col-lg-4 col-11 text-center'>
                        <EBookModal />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactSection;