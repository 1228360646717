import React from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { downloadEBook } from "../services/ebook";
import download from "downloadjs";

class EBookModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      submitBtnDisabled: false,
      secret: ''
    }

    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  showModal() {
    this.setState({ isOpen: true });
  };

  hideModal() {
    this.setState({ isOpen: false });
  };

  handleInputChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.submitForm();
    }
  }

  async submitForm() {
    this.setState({ submitBtnDisabled: true });
    const res = await downloadEBook(this.state.secret);
    if (res.ok) {
        const blob = await res.blob();
        download(blob, "13 Spades Dealer Academy Manual.docx");
    }
    this.hideModal();
    this.setState({ submitBtnDisabled: false });
  }


  render() {
    return (
      <>
        <button className='btn btn-primary' onClick={this.showModal}>
            Download E-Book
            <i className="bi bi-book ms-2" />
        </button>
        <Modal show={this.state.isOpen} onHide={this.hideModal} centered>
          <Modal.Header className="modal-header">
            <Modal.Title className="modal-title">Download E-Book (Members Only)</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body">
          <form>
              <div className="form-group">
                  <label htmlFor="secret" className="col-form-label">Password:</label>
                  <input type="password" className="form-control" id="secret" name="secret" value={this.state.secret} onChange={this.handleInputChange} onKeyPress={this.handleKeyPress} />
              </div>
          </form>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <button id="submit" type="button" className="btn btn-info" onClick={this.submitForm} disabled={this.state.submitBtnDisabled}>Submit</button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default EBookModal;