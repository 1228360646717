import React from "react";
import '../styles/about.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import '../styles/contact.css'



const Contact = () => {

    return(
        <div>
            <header className="contact-header bg-fixed-2">
                <div id="contact-header-overlay" className='container h-100 text-center text-cdf'>
                    <div className='row h-100 justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <h1>Contact</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className='contact-content bg-dark text-light'>
                <section className='container-xl'>
                    <div className='row gap-2 justify-content-center p-5'>
                        <div className='row col-lg-5 col-10 justify-content-center'>
                            <h1>Contact Us</h1>
                            <hr />
                            <p>
                                <i className="bi bi-geo-alt-fill p-2 text-info" />
                                16 5th Street, McMechen, WV 26040
                            </p>
                            <p>
                                <i className="bi bi-telephone-fill p-2 text-info" />
                                Call or Text (304)-281-4451
                            </p>
                            <p>
                                <i className="bi bi-envelope-fill p-2 text-info" />
                                13spadesdealeracademy@gmail.com
                            </p>
                        </div>
                        <div className='col-lg-6 col-10'>
                            <iframe
                                className='w-100'
                                height={320}
                                title="Map"
                                allowFullScreen
                                referrerPolicy="no-referrer-when-downgrade"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d899.2040259816619!2d-80.7312850701489!3d39.99111354325078!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8835de9af52a879d%3A0x319b4987a6e641f2!2s16%205th%20St%2C%20McMechen%2C%20WV%2026040!5e1!3m2!1sen!2sus!4v1743462242400!5m2!1sen!2sus"
                            />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default Contact;