import React from "react";
import { Link } from "react-router-dom";
import '../styles/about.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import img_hs1 from '../images/IMG_4967_43.jpg'
import ContactSection from "./contactSection";

const About = () => {

    return(
        <div>
            <header className="about-header bg-fixed-2">
                <div id="about-header-overlay" className='container h-100 text-center text-cdf'>
                    <div className='row h-100 justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <h1>About Us</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className='about-content bg-light'>
                <section className='container-xl about-me'>
                    <div className='row gap-2 justify-content-center p-3'>
                        <div className='col-lg-6 col-11'>
                            <img src={img_hs1} className="img-fluid" alt="owner and instructor" />
                        </div>
                        <div className='row col-lg-5 col-11 justify-content-center'>
                            <h1>Doug Price</h1>
                            <h5>Owner &amp; Head Instructor</h5>
                            <hr />
                            <p>
                                Since 2007 I have dealt, supervised, and managed poker rooms at casinos
                                in Pennsylvania and West Virginia. I have 11 years of experience in training,
                                industry knowledge, and auditioning prospective poker dealers.
                            </p>
                            <p>
                                You will be given the proper instruction and trained on the best techniques
                                that will enable you to become a certified professional poker dealer. I will
                                give you industry tips and insight to make you a success in just 6 weeks.
                            </p>
                            <p>
                                13 Spades Dealer Academy will certify and assist all graduates in landing a spot
                                at one of the many casinos regionally or around the country!
                            </p>
                            <Link to='/pricing' className='col-8 btn btn-primary'>
                                Get Certified
                                <i className="bi bi-arrow-right mx-3"></i>
                            </Link>
                        </div>
                    </div>
                </section>
                <ContactSection />
            </main>
        </div>
    );
}

export default About;