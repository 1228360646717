// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Nav from './components/nav.js'
import About from './components/about.js'
import Home from './components/home.js'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Pricing from './components/pricing';
import Footer from './components/footer';
import Contact from './components/contact'
// import Gallery from './components/gallery'

function App() {
  return (
    <Router>
      <div className="App scroll-parallax">
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
