import React from "react";
import '../styles/pricing.css'
import SignupModal from "./signupModal"

import img_c1 from '../images/chipcut_32.jpg'
import ContactSection from "./contactSection";

const Pricing = () => {

    return(
        <div>
            <header className="pricing-header bg-fixed-2">
                <div id="pricing-header-overlay" className='container h-100 text-center text-cdf'>
                    <div className='row h-100 justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <h1>Pricing</h1>
                        </div>
                    </div>
                </div>
            </header>
            <main className='pricing-content bg-light'>
                <section className='container-xl bg-light'>
                    <div className='row justify-content-center p-2'>
                        <div className='col-lg-10 text-center'>
                            <h1>Pricing</h1>
                            <hr />
                            <p>
                                Classes run two days a week for 6 weeks.
                                You can choose to attend either the day or the night class.
                                <br />
                                Day schedule is Monday &amp; Tuesday 11AM - 4PM
                                <br />
                                Night schedule is Tuesday &amp; Wednesday 5PM - 10PM
                                <br />
                                If you feel that you are not ready after 6 weeks, you can continue
                                to come to the next set of classes for free! We like to see our students
                                graduate and succeed so we will make every effort to accomodate you until you are ready.
                            </p>
                            <b>All students will receive one deck of your choice of Premium Casino Grade playing cards and cut card</b>
                        </div>
                    </div>
                </section>
                <section className='container-xl p-0'>
                    <div className='row justify-content-center p-5'>
                        <div className='col-lg-10 text-center'>
                            <h1>Payment Options</h1>
                            <hr />
                        </div>
                    </div>
                    <div className='row w-100 m-0 justify-content-center align-items-start text-center'>
                        <div className='col-11 col-lg'>
                            <div 
                                className='card border-0 shadow my-3'
                                data-aos='fade-up'
                                data-aos-duration='1000'
                                data-aos-anchor-placement='top-center'
                            >
                                <img src={img_c1} className="card-img-top" alt="counting chips" />
                                <div className='card-body'>
                                    <h2 className='card-title'>Weekly Plan</h2>
                                    <p className='card-text'>
                                        Payments can be made before the beginning of the first class each week. 
                                        The standard rate for students paying on a weekly basis is $210 per week.
                                    </p>
                                    <h5>Casino Employee Discount</h5>
                                    <p>Discounts are available for all casino employees. Just bring in your casino ID or last pay stub and get 10% off. ($189/week)</p>
                                    <h5>Armed Forces Discount</h5>
                                    <p>We show love to all of our military veterans! Bring in your military ID and also receive a 10% discount. ($189/week)</p>
                                    <hr />
                                    <h3 className='p-3'>$210 per week</h3>
                                    <SignupModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-11 col-lg'>
                            <div 
                                className='card border-0 shadow my-3'
                                data-aos='fade-up'
                                data-aos-duration='1000'
                                data-aos-anchor-placement='top-center'
                                data-aos-delay='500'
                            >
                                <img src={img_c1} className="card-img-top" alt="counting chips" />
                                <div className='card-body'>
                                    <h2 className='card-title'>Up-front Payment</h2>
                                    <p className='card-text'>
                                        If you choose to pay up-front for the course in full, you will receive a $60 discount ($1200 total).
                                        You may attend classes as long as you need (accomodations willing) at no extra charge.
                                    </p>
                                    <h5>Casino Employee Discount</h5>
                                    <p>The 10% Casino Employee Discount also applies to this payment option, bringing the total cost of the course to $1080.</p>
                                    <h5>Armed Forces Discount</h5>
                                    <p>The 10% Armed Forces Discount also applies to this payment option, bringing the total cost of the course to $1080.</p>
                                    <hr />
                                    <h3 className='p-3'>$1200</h3>
                                    <SignupModal />
                                </div>
                            </div>
                        </div>
                        <div className='col-11 col-lg'>
                            <div 
                                className='card border-0 shadow my-3'
                                data-aos='fade-up'
                                data-aos-duration='1000'
                                data-aos-anchor-placement='top-center'
                                data-aos-delay='1000'
                            >
                                <img src={img_c1} className="card-img-top" alt="counting chips" />
                                <div className='card-body'>
                                    <h2 className='card-title'>Payment Channels</h2>
                                    <p className='card-text'>Preferred payment is with cash, but you are free to pay however you wish!</p>
                                    <p className='card-text'>Payment channels:</p>
                                    <ul>
                                        <li>Cash, Credit Card, and Debit Card</li>
                                        <li>Venmo: @Doug-Price-20 (Preferred)</li>
                                        <li>CashApp: $Dougprice20</li>
                                        <li>PayPal: freshoop@comcast.net</li>
                                    </ul>
                                    <hr />
                                    <p>
                                        Make a $50 deposit to secure your spot in the class. This payment will go towards your total payment.
                                        The remaining first week balance will be due by the first day of class.
                                    </p>
                                    <h3 className='p-3'>$50</h3>
                                    <SignupModal />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center p-1'>
                        <div className='col-11 text-center'>
                            <p>** 13 Spades Dealer Academy is not responsible for ensuring students are able to obtain state gaming licenses or pass a background check. **</p>
                        </div>
                    </div>
                </section>
                <ContactSection />
            </main>
        </div>
    );
}

export default Pricing;